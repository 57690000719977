import "./email.css";

const Email = () => {
  return (
    <div className="email">
      <a href="mailto:psk.rin@ya.ru">psk.rin@ya.ru</a>
    </div>
  );
};

export default Email;