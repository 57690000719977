
import axios from "axios";
import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
// import { NavLink } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from "react-bootstrap/Breadcrumb";


const ConcreteRepair = () => {
  const [cardList, setCardList] = useState({});

  useEffect(() => {
    axios({
      method: "get",
      // url: "http://127.0.0.1:8000/api/v1/serviseCardConcreteRepair/",
      url: "https://pskrin.ru/api/v1/serviseCardConcreteRepair/",
    })
      .then((response) => {
        setCardList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <Container className="view_size">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
        <Breadcrumb.Item active>Ремонт бетона</Breadcrumb.Item>
      </Breadcrumb> 
      <h1 className=" h1 text-center m-5">Ремонт бетона</h1>
      <Row xs={1} md={2} lg={3}>
        {Array.from(cardList).map((card_item, idx) => (
          <Col key={idx} className=" d-flex justify-content-center m-auto">
            <Card 
              style={{ width: "20rem" }} 
              className=" m-3 bg-body-secondary"
              >
              
              <Card.Img
                style={{ width: "20rem", height: "18rem" }}
                variant="top"
                className="img-thumbnail"
                src={card_item.servise_img}
              />
              <Card.Body className=" bg-body-secondary">
                <Card.Title className=" text-center ">
                  {card_item.title}
                </Card.Title>
              </Card.Body>
              <Button 
                variant="outline-primary" 
                className=" m-3" >
                  <Nav.Link 
                    as={Link}
                    to= "/description_concrete_repair"
                    state={{ problem_description_id: card_item.problem_description_id }} 
                  >
                    Подробнее
                  </Nav.Link>
                  </Button>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ConcreteRepair;