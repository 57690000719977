import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import "./home.css";


const Home = () => {
  return (
    <div className="main">
      <Container>
        <Row className="p-5">
          <Col md={7} className="title mt-4">
            <h1 className="m-3 h1 text-uppercase  ">
              <span className="span">Срочное решение</span> проблем
              гидроизоляции и ремонта бетонных и каменных конструкций
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5 bd-highlight d-inline-block text-center d-flex  gap-4 justify-content-center">
            <Button variant="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>{" "}
              
              <NavLink to='/consultations'> Выезд специалиста</NavLink>
            </Button>
            <Button variant="info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
              <NavLink to='/consultations'> Совет специалиста</NavLink>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
