// import Container from 'react-bootstrap/Container';
import Articles from './components/Articles_page/articles';
import Contacts from './components/Contacts_page/contacts';
import Documentation from './components/Doс_page/documentation';
import Home from './components/Home_page/home';
import ServiseDescription from './components/Servise_description_page/serviseDescription';
import ConcreteRepair from './components/Servises_pages/concrete_repair';
import Consultations from './components/Servises_pages/consultations';
import Waterproofing from './components/Servises_pages/waterproofing';
import ProblemContentConcreteRepair from './components/Servises_pages/problemContentConcreteRepair';
import Footer from './components/footer/footer';
import Header from './components/header';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { useEffect } from "react";




function App() {
  useEffect(() => emailjs.init("JDmsRXypO9GVmCnBN"), []);
  return (
    <div>
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/consultations" element={<Consultations />}></Route>
          <Route path="/waterproofing" element={<Waterproofing />}></Route>
          <Route path="/concrete_repair" element={<ConcreteRepair />}></Route>
          <Route path="/documentation" element={<Documentation/>}></Route>
          <Route path="/articles" element={<Articles />}></Route>
          <Route path="/contacts" element={<Contacts />}></Route>
          <Route path="/description_waterproofing" element={<ServiseDescription/>}></Route>
          <Route path="/description_concrete_repair" element={<ProblemContentConcreteRepair/>}></Route>
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
