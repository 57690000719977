import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/esm/Container';
import Phone from '../phone/Phone';
import Email from '../email/email';
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";

const Contacts = () => {
    return (
        <Container className='view_size'>
            <h1 className=" h1 text-center m-5">Наши контакты и реквизиты</h1>
            <Stack gap={3}>
                <div className="p-2 bg-body-secondary"><p>Мы выполним для Вас работы в г.Екатеринбурге и городах Свердловской области</p></div>
                <div className="p-4">
                        <p className='text-decoration-underline'>Для консультаций и вызова специалиста:</p>
                        <p>Телефон:<Phone></Phone></p>
                        <p>Почта:<Email></Email></p>
                        <Button><NavLink to='/consultations' >Форма обратной связи</NavLink></Button>
                    </div>
                <div className="p-2">
                    <p className=' text-decoration-underline'>Реквизиты:</p>
                    <p>ООО ПСК «РиН»</p>
                    <p>ИНН 6658412540 КПП 667101001</p>
                    <p>ОГРН 1126658020653 ОКПО 12319741</p>
                    <p>
                    620105 г.Екатеринбург. ул. Вильгельма де Геннина д.47, кв. 257
                    </p>
                </div>
            </Stack>
        </Container> 
     );
}
 
export default Contacts;

