import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../img/logoW.png";


const Header = () => {
  return (
    <Navbar
      expand="lg"
      bg="dark"
      data-bs-theme="dark"
      className="bg-body-tertiary"
    >
      <Container>
        <Navbar.Brand>
          <NavLink to="/">
            <img
              src={logo}
              width="120"
              height="60"
              className="d-inline-block "
              alt="ПСК РиН"
            />
          </NavLink>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ma-auto">
            <Nav.Link>
              <NavLink to="/">Главная</NavLink>
            </Nav.Link>

            <NavDropdown title="Сервисы" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <NavLink to="/consultations">Консультации и выезды</NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink to="/waterproofing">Гидроизоляция</NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink to="/concrete_repair">Ремонт бетона</NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link ><NavLink to="/documentation">Документация</NavLink></Nav.Link> */}
            {/* <Nav.Link ><NavLink to="/articles">Статьи</NavLink></Nav.Link> */}
            <Nav.Link>
              <NavLink to="/contacts">Контакты</NavLink>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
