

const Articles = () => {
    return ( 
        <>
            <div>Статьи на тему</div>
        </>
     );
}
 
export default Articles;