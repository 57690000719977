import "./phone.css";

const Phone = () => {
  return (
    <div className="phone">
      <a href="tel:+79221343910">+7(922)134-39-10</a>
    </div>
  );
};

export default Phone;
