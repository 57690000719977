import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink } from "react-router-dom";

const ServiseDescription = () => {
  let location = useLocation();
  const { problem_description_id } = location.state;

  const [serviseDescription, setserviseDescription] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      // url: `http://127.0.0.1:8000/api/v1/serviseDescription/${problem_description_id}/`,
      url: `https://pskrin.ru/api/v1/serviseDescription/${problem_description_id}/`,
    })
      .then((response) => {
        setserviseDescription(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [problem_description_id]);

  return (
    <Container className="view_size">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
          <Breadcrumb.Item >
            <NavLink to= "/waterproofing" >Гидроизоляция</NavLink>
          </Breadcrumb.Item>
        <Breadcrumb.Item active>О проблеме</Breadcrumb.Item>
      </Breadcrumb>      
      <h1 className=" h1 text-center m-5">{serviseDescription.problem_title}</h1>
      <Row xs={1} md={2} >
        <Col className="d-flex justify-content-center ">
          <Image 
          
            variant="top"
            style={{ width: "24rem", height: "24rem" }}
            src={serviseDescription.problem_img} 
            thumbnail 
            />
        </Col>
        <Col>
          <p>{serviseDescription.problem_solution}</p>
            <Row>
              <div className=" d-flex justify-content-around">
                <Button 
                  variant="primary">
                    <NavLink to='/consultations'>Отправить заявку</NavLink>
                </Button>
                {/* <Button 
                  variant="outline-secondary">
                    <NavLink to='/articles'>Подробнее в статье</NavLink>
                </Button> */}
              </div>
            </Row>
            </Col>
          </Row>

    </Container>
  );
};

export default ServiseDescription;
