import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Email from "../email/email";
import Phone from "../phone/Phone";
import './footer.css'


const Footer = () => {
  return ( 
    <div className="footer">
      <Container >
        <Row className="footer p-3">
          <Col className=" text-start" ><Email></Email></Col>
          <Col className="col-lg-3 d-flex justify-content-center">ПСК "РиН"</Col>
          <Col className=" text-end"><Phone></Phone></Col>
        </Row>
      </Container>
    </div>
   );
}
 
export default Footer;