// import "../../index.css";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const Consultations = () => {
  const [data, setData] = useState({
    klient_name: "",
    klient_email: "",
    service_consultion: false,
    service_visit: false,
    klient_content: "",
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };


  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault(); 
    emailjs
      .sendForm(
        "service_zssbvwc",
        "template_90hhuuu",
        form.current,
        "JDmsRXypO9GVmCnBN"
      )
      .then(
        (result) => {
          // show the user a success message
          alert('Заявка отправлена. На Вашу почты Мы отправим ответ.')
        },
        (error) => {
          // show the user an error
          alert('Что-то пошло не так. Попрабуйте ещё раз.')
        }
      );

    axios({
      method: "post",
      // url: "http://127.0.0.1:8000/api/v1/klient_request/",
      url: "https://pskrin.ru/api/v1/klient_request/",
      data: data,
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });
    setData({
      klient_name: "",
      klient_email: "",
      service_consultion: "",
      service_visit: "",
      klient_content: "",
    });

  };

  return (
    <Container className="view_size">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
        <Breadcrumb.Item active>Консультации и выезды</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="h1 text-center m-5">Советы специалиста и выезды</h1>
      <Form
        // onSubmit={handleSubmit}
        onSubmit={sendEmail}
        ref={form}
        className=" m-5 bg-body-secondary p-3"
      >
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className=" fw-bold">Как к Вам обращаться?</Form.Label>
          <Form.Control
            name="klient_name"
            value={data.klient_name}
            onChange={handleChange}
            type="text"
            placeholder="Ваше имя"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className=" fw-bold">Email</Form.Label>
          <Form.Control
            name="klient_email"
            value={data.klient_email}
            onChange={handleChange}
            type="email"
            placeholder="Ваш адрес электронной почты"
          />
          <Form.Text className="text-muted">
            По этому адресу мы отправим ответ на Ваш вопрос
          </Form.Text>
        </Form.Group>
        <Form.Label className=" fw-bold">Выберите услугу:</Form.Label>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            name="service_consultion"
            value={true}
            onChange={handleChange}
            type="checkbox"
            label="Совет специалиста"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            name="service_visit"
            value={true}
            onChange={handleChange}
            type="checkbox"
            label="Выезд специалиста"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className=" fw-bold">Описание проблемы</Form.Label>
          <Form.Control
            name="klient_content"
            value={data.klient_content}
            onChange={handleChange}
            as="textarea"
            placeholder="Кратко о Вашей проблеме"
            rows={3}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Отправить
        </Button>
      </Form>
    </Container>
  );
};
export default Consultations;
